import { useTimeout } from '@hp/utils';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ScrollLock, { TouchScrollable } from 'react-scrolllock';

import {
  CloseButton,
  ModalContainer,
  ModalContent,
  ModalOverlay,
  Progress,
  ProgressWrapper,
} from './styled';

export enum ModalWellKnowResult {
  OK = 'OK',
  NO = 'NO',
  ESC = 'ESC',
  TIMEOUT = 'TIMEOUT',
}

type ModalProps = {
  onCloseRequest: (result: ModalWellKnowResult) => void;
  desktopMaxWidth?: number;
  disableClose?: boolean;
  timeout?: number;
};

export const Modal: FC<ModalProps> = ({
  onCloseRequest,
  desktopMaxWidth = 1000,
  children,
  disableClose,
  timeout = 0,
}) => {
  const modal = useRef(null);
  const [countDown, setCountDown] = useState(timeout);

  /* any number, lower number = smooth progress; higher number = better performance */
  const timeoutStepMs = 50;

  const handleKeyUp = useCallback(
    (e) => {
      const keys = {
        /* ESC key */
        27: () => {
          e.preventDefault();
          onCloseRequest(ModalWellKnowResult.ESC);
          window.removeEventListener('keyup', handleKeyUp, false);
        },
      };

      if (keys[e.keyCode]) {
        keys[e.keyCode]();
      }
    },
    [onCloseRequest],
  );

  const handleOutsideClick = useCallback(
    (e) => {
      if (modal) {
        if (!modal.current.contains(e.target)) {
          onCloseRequest(ModalWellKnowResult.ESC);
          document.removeEventListener('click', handleOutsideClick, false);
        }
      }
    },
    [onCloseRequest],
  );

  useEffect(() => {
    if (disableClose) return;
    window.addEventListener('keyup', handleKeyUp, false);
    document.addEventListener('click', handleOutsideClick, false);

    return () => {
      window.removeEventListener('keyup', handleKeyUp, false);
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, [handleKeyUp, handleOutsideClick, disableClose]);

  useTimeout(
    () => {
      setCountDown((prev) => {
        if (prev <= 0) return prev;
        const newValue = prev - timeoutStepMs;
        setCountDown(newValue);
        if (newValue <= 0) onCloseRequest(ModalWellKnowResult.TIMEOUT);
      });
    },
    timeoutStepMs,
    [countDown /* adding dependency to countDown starts a new timeout */],
  );

  const percentProgress = timeout >= 0 ? countDown / (timeout / 100) : 0;

  return (
    <>
      <ModalOverlay>
        <ModalContainer ref={modal} desktopMaxWidth={desktopMaxWidth}>
          {percentProgress ? (
            <ProgressWrapper>
              <Progress style={{ width: `${percentProgress}%` }} />
            </ProgressWrapper>
          ) : null}
          {!disableClose && (
            <CloseButton
              type="button"
              onClick={() => onCloseRequest(ModalWellKnowResult.ESC)}
              data-test="modal.button.close"
            />
          )}
          <ScrollLock />
          <TouchScrollable>
            <ModalContent>{children}</ModalContent>
          </TouchScrollable>
        </ModalContainer>
      </ModalOverlay>
    </>
  );
};
