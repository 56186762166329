import {
  Button,
  ButtonType,
  ErrorIcon,
  Typography,
  TypographyType,
} from '@hp/atomic';
import { Centered, CenteredProps } from '@hp/layout';
import { Modal, ModalWellKnowResult } from '@hp/modal';
import { colors, spacing } from '@hp/theme';
import { Trans } from '@lingui/macro';
import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

export { ModalWellKnowResult } from '@hp/modal';

const SystemModalContext = React.createContext<SystemModalContextProps>(null);

type ModalProps = {
  content: JSX.Element;
  onClose: (result: ModalWellKnowResult) => void;
  desktopMaxWidth?: number;
  disableClose?: boolean;
  timeout?: number;
};

type SystemModalContextProps = {
  modalProps: ModalProps;
  setModalProps: React.Dispatch<React.SetStateAction<ModalProps>>;
};

const Wrapper = styled.div`
  padding: ${spacing.m};
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${spacing.l};
`;

const defaultError = (
  <Trans id="errors.general">Došlo k neočekávané chybě</Trans>
);

export const InfoContent: React.FC<
  { buttons?: JSX.Element } & CenteredProps
> = ({ children, buttons, ...centeredProps }) => {
  return (
    <Wrapper>
      <Centered spacingBetween="m" {...centeredProps}>
        {children}
      </Centered>
      {buttons && <ButtonsWrapper>{buttons}</ButtonsWrapper>}
    </Wrapper>
  );
};

const ErrorContent: React.FC<{ buttons: JSX.Element }> = ({
  children,
  buttons,
}) => {
  return (
    <Wrapper>
      <Centered spacingBetween="m">
        <ErrorIcon />
        <Typography type={TypographyType.BodyMicro} color={colors.error}>
          {children}
        </Typography>
        {buttons}
      </Centered>
    </Wrapper>
  );
};

export const SystemModalProvider: React.FC = ({ children }) => {
  const [modalProps, setModalProps] = useState<ModalProps>(null);
  return (
    <SystemModalContext.Provider value={{ modalProps, setModalProps }}>
      {children}
      {modalProps?.content && (
        <Modal
          disableClose={modalProps.disableClose}
          onCloseRequest={(result) => modalProps?.onClose(result)}
          desktopMaxWidth={modalProps.desktopMaxWidth ?? 400}
          timeout={modalProps.timeout}
        >
          {modalProps.content}
        </Modal>
      )}
    </SystemModalContext.Provider>
  );
};

export type ModalInvokeCloseFunction<T = ModalWellKnowResult> = (
  trigger: T | ModalWellKnowResult,
) => void;

export type ElementBuilderFunction<T = ModalWellKnowResult> = (
  invokeClose: ModalInvokeCloseFunction<T>,
) => JSX.Element;

type LayoutBuilderFunction = (
  message: JSX.Element,
  buttons: JSX.Element,
) => JSX.Element;

export const useSystemModal = () => {
  const { setModalProps } = useContext(SystemModalContext);

  const showModalMutable = <T extends unknown = ModalWellKnowResult>(
    message: JSX.Element | ElementBuilderFunction<T>,
    buttons: ElementBuilderFunction<T> | false = (invokeClose) => (
      <Button
        buttonType={ButtonType.PRIMARY}
        onClick={() => invokeClose(ModalWellKnowResult.OK)}
        className="gaButton gaButtonAction_modalClose"
      >
        <Trans id="common.button.close">Zavřít</Trans>
      </Button>
    ),
    layout: LayoutBuilderFunction,
    otherProps: Pick<
      ModalProps,
      'desktopMaxWidth' | 'disableClose' | 'timeout'
    > = {},
  ) => {
    return new Promise<T | ModalWellKnowResult>((resolve) => {
      const invokeClose = (trigger: T | ModalWellKnowResult) => {
        setModalProps(null);
        resolve(trigger);
      };
      setModalProps({
        content: layout(
          typeof message === 'function' ? message(invokeClose) : message,
          buttons === false ? null : buttons(invokeClose),
        ),
        onClose: invokeClose,
        ...otherProps,
      });
    });
  };
  const showModal = useCallback(showModalMutable, [setModalProps]);

  const showInfo = <T extends unknown = ModalWellKnowResult>(
    message: JSX.Element | ElementBuilderFunction<T>,
    buttons?: ElementBuilderFunction<T> | false,
    options: Pick<
      ModalProps,
      'desktopMaxWidth' | 'disableClose' | 'timeout'
    > & {
      layout?: LayoutBuilderFunction;
    } = {},
  ) => {
    const { layout, ...otherProps } = options;

    return showModal<T>(
      message,
      buttons ??
        ((invokeClose) => (
          <Button
            buttonType={ButtonType.PRIMARY}
            onClick={() => invokeClose(ModalWellKnowResult.OK)}
            className="gaButton gaButtonAction_modalOK"
          >
            <Trans id="common.button.continue">Pokračovat</Trans>
          </Button>
        )),
      layout ??
        ((message, buttons) => (
          <InfoContent buttons={buttons}>{message}</InfoContent>
        )),
      otherProps,
    );
  };
  const showErrorInfo = <T extends unknown = ModalWellKnowResult>(
    message?: JSX.Element | ElementBuilderFunction<T>,
    buttons?: ElementBuilderFunction<T> | false,
    otherProps: Pick<
      ModalProps,
      'desktopMaxWidth' | 'disableClose' | 'timeout'
    > = {},
  ) =>
    showModal<T>(
      message ?? defaultError,
      buttons,
      (message, buttons) => (
        <ErrorContent buttons={buttons}>{message}</ErrorContent>
      ),
      otherProps,
    );
  return {
    close: () => setModalProps(null),
    showErrorInfo: useCallback(showErrorInfo, [showModal]),
    showInfo: useCallback(showInfo, [showModal]),
  };
};
